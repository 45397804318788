<template>
  <div class="security-convention">
    <Header></Header>
    <div class="main">
      <div class="flex flex-row align-center price-title">
        <img src="../assets/servePrice/card.png" alt="" />
        <span>安全约定</span>
      </div>
      <div class="chart-wrap">
        <img src="../assets/home/loginBg.png" alt="" />
        <div class="project-wrap">
          <h1 class="project-title">内江汽车服务有限公司与租赁人关于租赁车辆安全的约定</h1>
          <div class="list">
              <h2>车辆租赁是指由用车方自聘驾驶员完成运输任务，在车辆租赁期间，如发生交通事故或其他事件经相关部门鉴定非机械事故所造成车辆损坏、人员伤亡、停运等经济损失，经双方协商确定，由车辆租赁方承担以下责任：</h2>
              <p>一、车辆在租赁期间发生交通事故或其他事件，造成车辆停驶，在停驶期间，车辆租赁方按每天租车价的50%承担停驶损失。</p>
              <p>二、车辆在租赁期间发生交通事故，造成车辆损坏及人员伤亡，所产生的经济损失，经向保险公司索赔后，保险公司免赔拒赔部分由车辆租赁方承担。</p>
              <p>三、车辆在租赁期间，发生交通事故或其他事件应及时通知车辆公司，事故处理由租赁方全权负责，公司提供手续及负责保险理赔。</p>
              <p>四、如租赁方授权我司派员协助处理事故，处理事故所产生的差旅费、交通费、住宿费等相关费用由车辆租赁方承担。</p>
              <p>五、本约定系车辆租赁协议不可分割的部分，若与协议冲突则优先适用本约定。</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "SecurityConvention",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
    loadData() {},
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
/* 当下 */
.security-convention {
  background-color: #f5f7fa;
}
.main {
  width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
}
.price-title {
  padding: 18px 0;
  position: relative;
  background-color: #f5f7fa;
}
.price-title::after {
  content: "";
  width: 1072px;
  height: 1px;
  background-color: #dadada;
  position: absolute;
  left: 135px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.price-title img {
  margin-right: 2px;
}
.price-title span {
  font-size: 18px;
  font-weight: 400;
  color: #141414;
}
.chart-wrap {
  padding: 13px 0 310px 0;
  box-shadow: 1px 5px 9px 1px rgba(0, 0, 0, 0.18);
  margin-bottom: 20px;
}
.chart-wrap > img {
  width: 1174px;
  height: 175px;
}
/* 文章 */
.project-title{
    font-size: 26px;
    font-weight: 500;
    color: #333333;
    margin: 55px 0 70px 0;
    text-align: center;
}
h1,h2,p{
    text-align: left;
}
.list{
    width: 1120px;
    margin: 0 auto;
}
h2{
    font-size: 18px;
    color: #5F5F5F;
}
.list h2{
margin-bottom: 40px;
}
.list p{
    margin-bottom: 20px;
}
</style>
