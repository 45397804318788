<template>
  <div class="footer">
    <div class="site">
      <span>公司注册地址：内江市经济技术开发区汉晨路666号6幢</span>
      <span>联系电话：0832-2032666 18064942803</span>
    </div>
    <div class="num">
      <span>备案号：蜀ICP备16014000号-1 版本号：v1.02 </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {};
  },
  methods: {
    loadData() {},
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
.footer span {
  display: block;
  text-align: center;
}
.site {
  background-color: #2e3339;
  padding: 22px 0;
}
.num {
  padding: 10px 0;
  background-color: #212325;
}
.site span {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
.num span {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
</style>
