<template>
  <div class="flex flex-row justify-center header">
    <div class="flex flex-row justify-between align-center main">
      <div class="nav-img">
        <img src="../assets/header/nj-LOGO.png" alt="" />
      </div>
      <div class="flex flex-row justify-between align-center nav">
        <ul class="flex flex-row justify-between align-center">
          <!-- <li class="nav-active">首页</li> -->
          <li
            v-for="(list, index) in navList"
            :key="index"
            @click="navActive(index)"
            :class="{ 'nav-active': index == $store.state.activeIndex }"
          >
            {{ !list.listItem ? list.title : "" }}
            <el-dropdown
              v-if="list.listItem"
              trigger="click"
              @command="handleCommand"
            >
              <!-- <span class="el-dropdown-link"> {{ list.title }} <i class="el-icon-arrow-down el-icon--right"></i></span> -->
              <span class="el-dropdown-link"> {{ list.title }} </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in list.listItem"
                  :key="index"
                  :command="index"
                  >{{ item }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
        <div class="login-btn">
          <span v-if="!$store.state.accessToken" @click="openLogin">登录 </span>
          <div v-else>
            <!-- <span>{{$store.state.userName}}</span> -->
            <el-dropdown trigger="click" @command="loginSelect">
              <span
                class="
                  el-dropdown-link
                  flex flex-row
                  justify-between
                  align-center
                  user
                "
              >
                <span style="text-align: center;width: 80px;">{{ $store.state.userName }}</span><i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">后台管理</el-dropdown-item>
                <el-dropdown-item command="b">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {
      navList: [
        {
          title: "首页",
        },
        {
          title: "公司简介",
        },
        {
          title: "品牌车型",
        },
        {
          title: "服务报价",
          listItem: ["服务报价表", "优惠政策", "安全约定"],
        },
        // {
        //   title: "优惠政策",
        // },
        {
          title: "在线预约",
        },
      ],
      // activeIndex: 0,
    };
  },
  methods: {
    loadData() {},
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    handleCommand(command) {
      console.log(this.activeIndex);
      this.$store.commit("setActiveIndex", 3);
      if (command === 0) {
        this.$router.push({ path: "/ServicePrice" });
      } else if (command === 1) {
        this.$router.push({ path: "/PreferentialPolicy" });
      } else {
        this.$router.push({ path: "/SecurityConvention" });
      }
    },
    openLogin() {
      // this.activeIndex = 5;
      this.$store.commit("setActiveIndex", 5);
      this.$router.push({ path: "/Login" });
    },
    navActive(index) {
      if (index != 3) {
        this.$store.commit("setActiveIndex", index);
      }
      switch (index) {
        case 0:
          this.$router.push({ path: "/" });
          break;
        case 1:
          this.$store.commit("setActiveIndex", 0);
          if (this.$route.path == "/") {
            document.getElementById("firm-intro").scrollIntoView();
          }
          this.$router.push({ path: "/", query: { id: 0 } });
          break;
        case 2:
          this.$store.commit("setActiveIndex", 0);
          if (this.$route.path == "/") {
            document.getElementById("car-type").scrollIntoView();
          }
          this.$router.push({ path: "/", query: { id: 1 } });
          break;
        case 3:
          this.$store.commit("setActiveIndex", this.$store.state.activeIndex);
          break;
        default:
          this.$router.push({ path: "/OnlineTo" });
          break;
      }
    },
    loginSelect(command) {
      if (command == "b") {
        this.$store.commit("setUserName", "");
        this.$store.commit("setUserId", "");
        this.$store.commit("setToken", "");
        setTimeout(() => {
          window.localStorage.removeItem("user_name");
          window.localStorage.removeItem("user_id");
          window.localStorage.removeItem("access_token");
          this.$router.push({ path: "/Login" });
        }, 50);
      }else if(command == "a"){
        var username=encodeURIComponent(this.$store.state.userName);
        var password=this.encrypt(this.$store.state.password);
				window.open('http://cf.njgcgj.com:8081/api/loginToIndex?username='+username+'&password='+password)
      }
    },
    /*
    *功能：对url加密算法（只针对window.location.href跳转，不针对post表单提交及ajax方式）
    *算法：对于暴露在浏览器地址栏中的属性值进行加密，如一个属性为agentID=1，
    *     若对1加密后为k230101io934jksd32r4，说明如下：
    *     前三位为随机数；
    *     第四到第五位为要加密字符转换成16进制的位数，
    *       如：要加密字符为15转换成16进制为f，位数为1，则第四、五位为01；
    *     第六位标识要加密字符为何种字符，0：纯数字，1：字符
    *       若是字符和数字的混合，则不加密；
    *     从第七位开始为16进制转换后的字符（字母和非数字先转换成asc码）；
    *     若加密后的字符总位数不足20位，则用随机数补齐到20位，若超出20位，则不加随机数。
    *     即加密后总位数至少为20位。
    */
  encode16(str){
      // str=str.toLowerCase();
      if (str.match(/^[-+]?\d*$/) == null){//非整数字符，对每一个字符都转换成16进制，然后拼接
        var s=str.split("");
        var temp="";
        for(var i=0;i<s.length;i++){
          s[i]=s[i].charCodeAt();//先转换成Unicode编码
          s[i]=s[i].toString(16);
          temp=temp+s[i];
        }
        return temp+"{"+1;//1代表字符
      }else{//数字直接转换成16进制
        str=parseInt(str).toString(16);
      }
      return str+"{"+0;//0代表纯数字
    },

  produceRandom(n){
      var num="";
      for(var i=0;i<n;i++)
      {
        num+=Math.floor(Math.random()*10);
      }
      return num;
  },
    //加密函数
	 encrypt(str){
		var encryptStr="";//最终返回的加密后的字符串
		encryptStr+=this.produceRandom(3);//产生3位随机数

		var temp=this.encode16(str).split("{");//对要加密的字符转换成16进制
		var numLength=temp[0].length;//转换后的字符长度
		numLength=numLength.toString(16);//字符长度换算成16进制
		if(numLength.length==1){//如果是1，补一个0
			numLength="0"+numLength;
		}else if(numLength.length>2){//转换后的16进制字符长度如果大于2位数，则返回，不支持
			return "";
		}
		encryptStr+=numLength;

		if(temp[1]=="0"){
			encryptStr+=0;
		}else if(temp[1]=="1"){
			encryptStr+=1;
		}

		encryptStr+=temp[0];

		if(encryptStr.length<20){//如果小于20位，补上随机数
			var ran=this.produceRandom(20-encryptStr.length);
			encryptStr+=ran;
		}
		return encryptStr;
	}
  },
  mounted() {},
};
</script>
<style scoped>
.align-center li,.el-dropdown-link{
  font-size: 18px;
  color: #141414;
}
.header {
  height: 85px;
  background-color: #ffffff;
}
.main {
  width: 1200px;
}
.nav-img img {
  width: 256px;
  height: 40px;
}
.nav ul {
  width: 500px;
  margin-right: 68px;
}
.nav li {
  list-style: none;
  /* padding-bottom: 10px; */
  cursor: pointer;
}
.nav-active {
  position: relative;
}
.nav-active::after {
  content: "";
  width: 27px;
  height: 3px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3991fb;
}
.login-btn {
  width: 120px;
  height: 23px;
  background-color: #3991fb;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
}
/* .list-item{
   display: block;
  text-align: center;
} */
.el-dropdown-menu.el-popper .el-dropdown-menu__item {
  text-align: center;
}
.user {
  width: 120px;
  /* display: block; */
  color: #ffffff;
  line-height: 23px;
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
